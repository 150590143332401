<template>
  <DetailTemplate
    :customClass="'product-detail detail-page'"
    v-if="getPermission('product:view')"
  >
    <template v-slot:sidebar-detail>
      <v-card class="h-100" elevation="0">
        <div class="d-flex px-0 py-3">
          <!-- <v-btn v-on:click="goBack" icon plain>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer> -->
          <template v-if="pageLoading">
            <v-skeleton-loader
              class="custom-skeleton height-40px custom-skeleton-full-width"
              width="600"
              type="text"
            >
            </v-skeleton-loader>
          </template>
          <template v-else>
            <div>
              <v-chip
                v-if="detail.activated == 1"
                class="mx-2"
                color="#66bb6a"
                label
                text-color="white"
              >
                Active
              </v-chip>
              <v-chip
                v-if="detail.activated == 0"
                class="mx-2"
                color="red"
                text-color="white"
                label
              >
                In-Active
              </v-chip>
            </div>
          </template>
        </div>
        <div class="px-2 py-5 text-center">
          <ImageNew
            :images="detail.product_images"
            :parent="detail.id"
            type="service"
            :isPageLoading="pageLoading"
          ></ImageNew>
          <!-- <h1
            class="form-title custom-nowrap-ellipsis text-capitalize mb-0 mt-3"
          >
            {{ detail.name }}
          </h1> -->
        </div>
        <div class="pt-0">
          <table class="width-100">
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Basic Information
              </th>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-1" width="150">
                Product #
              </td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.barcode">
                  <v-chip
                    small
                    label
                    color="chip-custom-blue"
                    outlined
                    class="text-white"
                    >{{ detail.barcode }}</v-chip
                  >
                </span>
                <em v-else class="text-muted"> no item number </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-1">Product No.</td>
              <td class="font-size-18 py-1">
                <span
                  v-if="detail?.product_prefix || detail?.product_prefix_no"
                >
                  <v-chip
                    small
                    label
                    color="chip-custom-blue mr-1 text-capitalize"
                    outlined
                    class="text-white"
                    >{{ detail?.product_prefix }}-{{
                      detail?.product_prefix_no
                    }}</v-chip
                  >
                  <showBarcode
                    v-if="false"
                    :barcodeUrl="detail.serial_image"
                    :barcode-text="detail.serial_no"
                    title="Barcode"
                  />
                </span>
                <em v-else class="text-muted"> no product no </em>
              </td>
            </tr>
            <!-- <tr>
              <td class="font-size-18 font-weight-500 py-1">Part Number</td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.part_number">{{
                  detail.part_number
                }}</span>
                <em v-else class="text-muted"> no part number </em>
              </td>
            </tr> -->
            <tr>
              <td class="font-size-18 font-weight-500 py-1">Brand</td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.brand">{{ detail.brand }}</span>
                <em v-else class="text-muted"> no brand </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-1">Category</td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.category">
                  <v-chip small label color="blue" outlined class="text-white">
                    {{ detail.category }}
                  </v-chip>
                </span>
                <em v-else class="text-muted"> no category </em>
              </td>
            </tr>
            <!-- <tr>
              <td class="font-size-18 font-weight-500 py-1">Tag</td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.tags">
                  <v-chip small label color="blue" outlined class="text-white">
                    {{ detail.tags }}
                  </v-chip>
                </span>
                <em v-else class="text-muted"> no tag </em>
              </td>
            </tr> -->
          </table>
          <table class="mt-5" width="100%">
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Pricing Information
              </th>
            </tr>
            <!-- <tr>
              <td class="font-size-18 font-weight-500 py-1" width="150">
                Supplier
              </td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.display_name">{{
                  detail.display_name
                }}</span>
                <em v-else class="text-muted"> no Supplier </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-1" width="150">
                Selling/Unit Price
              </td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.selling_cost">{{
                  detail.selling_cost
                }}</span>
                <em v-else class="text-muted"> no Selling/Unit Price </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-1">Purchase Price</td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.purchase_cost">{{
                  detail.purchase_cost
                }}</span>
                <em v-else class="text-muted"> no Purchase Price </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-1" width="150">
                Start Date
              </td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.start_date">{{
                  formatDate(detail.start_date)
                }}</span>
                <em v-else class="text-muted"> no start date </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-1" width="150">
                End Date
              </td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.end_date">{{
                  formatDate(detail.end_date)
                }}</span>
                <em v-else class="text-muted"> no end date </em>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <V-divider></V-divider>
              </td>
            </tr> -->
            <!-- <tr>
              <td class="font-size-18 font-weight-500 py-1" width="150">
                Has Custom Tax?
              </td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.custom_tax">{{
                  detail.custom_tax
                }}</span>
                <em v-else class="text-muted"> no custom tax </em>
              </td>
            </tr> -->
            <template v-if="detail.custom_tax == 1">
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  Tax Name
                </td>
                <td class="font-size-18 py-1 text-capitalize">
                  <span v-if="detail && detail.tax_name">{{
                    detail.tax_name
                  }}</span>
                  <em v-else class="text-muted"> no tax name </em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  Tax Rate (%)
                </td>
                <td class="font-size-18 py-1">
                  <span v-if="detail && detail.tax_rate">{{
                    detail.tax_rate
                  }}</span>
                  <em v-else class="text-muted"> no tax rate </em>
                </td>
              </tr>
            </template>
            <tr>
              <td class="font-size-18 font-weight-500 py-1" width="150">
                Purchase date
              </td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.purchase_date">{{
                  formatDate(detail.purchase_date)
                }}</span>
                <em v-else class="text-muted"> no purchase date </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-1" width="150">
                Purchase Order
              </td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.purchase_order">{{
                  detail.purchase_order
                }}</span>
                <em v-else class="text-muted"> no purchase order </em>
              </td>
            </tr>
            <!--  <tr>
              <td class="font-size-18 font-weight-500 py-1">
                Discounted Price
              </td>
              <td class="font-size-18 py-1">
                <span v-if="detail && detail.discount_price">{{
                  detail.discount_price
                }}</span>
                <em v-else class="text-muted"> no Discounted Price </em>
              </td>
            </tr> -->
          </table>
          <table class="mt-5" width="100%">
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Warranty Information
              </th>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-1" width="150">
                Duration
              </td>
              <td class="font-size-18 py-1">
                <span v-if="detail.warranty_check && detail.duration_time"
                  >{{ detail.warranty_check }}
                  <span class="text-capitalize"
                    >{{ detail.duration_time }}
                    <template v-if="detail.warranty_check != 1">(s)</template>
                  </span>
                </span>
                <em v-else class="text-muted">no warranty</em>
              </td>
            </tr>
          </table>
        </div>
      </v-card>
    </template>
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1
            class="form-title custom-nowrap-ellipsis text-capitalize mb-0 mr-2"
          >
            {{ detail.name }}
          </h1>
          <!-- <v-chip
            label
            color="chip-custom-blue"
            outlined
            class="text-white p-3"
            style="font-size: 21px !important; font-weight: 700"
            >{{ detail.barcode }}</v-chip
          > -->
          <!-- <v-chip
            v-if="detail.activated == 1"
            class="mx-2"
            color="#66bb6a"
            label
            outlined
            text-color="green"
          >
            Active
          </v-chip>
          <v-chip
            v-if="detail.activated == 0"
            class="mx-2"
            color="red"
            outlined
            text-color="red"
            label
          >
            In-Active
          </v-chip> -->
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <template v-if="getPermission('product:update')">
        <v-skeleton-loader
          class="custom-skeleton d-inline-block"
          v-if="pageLoading"
          type="button"
        >
        </v-skeleton-loader>
        <v-menu
          v-else
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              depressed
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in productMoreAction">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <template v-if="getPermission('product:update')">
        <v-btn
          :disabled="!formValid || formLoading || pageLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          depressed
          :to="
            getDefaultRoute('product.update', {
              param: { id: product },
            })
          "
        >
          <v-icon size="20">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <v-btn
        depressed
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid class="px-0">
        <v-tabs
          v-model="productTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent"
        >
          <template v-for="(tab, index) in tabs">
            <v-tab
              v-if="getPermission(tab.permission + ':view')"
              class="font-size-16 font-weight-600 px-8"
              :key="index"
              :href="'#' + tab.key"
              :disabled="tab.disabled"
            >
              <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                <!--begin::Svg Icon-->
                <inline-svg
                  :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
                />
                <!--end::Svg Icon-->
              </span>
              {{ tab.title }}
              <span v-if="tab.title == 'Supplier'">
                ({{
                  detail.product_supplier ? detail.product_supplier.length : 0
                }})
              </span>
              <span v-if="tab.title == 'Pricing'">
                ({{
                  detail.product_pricelogs
                    ? detail.product_pricelogs.length
                    : 0
                }})
              </span>
              <span v-if="tab.title == 'Inventory'">
                ({{ totalSerialnumRows }})
              </span>
            </v-tab>
          </template>
        </v-tabs>
        <v-card class="mt-0" elevation="0">
          <v-tabs-items v-model="productTab">
            <v-tab-item v-if="getPermission('product:view')" :value="'product'">
              <Overview :detail="detail"></Overview>
            </v-tab-item>
            <v-tab-item v-if="getPermission('product:view')" value="supplier">
              <ProductSpplierListing
                type="supplier"
                :product="detail.id"
                v-if="detail.id"
              ></ProductSpplierListing>
            </v-tab-item>

            <v-tab-item v-if="getPermission('product:view')" value="pricing">
              <ProductPriceListing
                type="pricing"
                :price-data="detail.product_pricelogs"
                :product="detail.id"
                @success="getProductDetail()"
              ></ProductPriceListing>
            </v-tab-item>
            <v-tab-item v-if="getPermission('product:view')" :value="'image'">
              <div class="service-detail-height">
                <Images
                  :images="detail.product_images"
                  :parent="detail.id"
                  type="product"
                  v-on:data:refresh="getProductDetail()"
                  :isPageLoading="pageLoading"
                  @update:primary="
                    ($event) => (detail['product_images'] = $event)
                  "
                ></Images>
              </div>
            </v-tab-item>

            <v-tab-item
              v-if="getPermission('product:view')"
              :value="'attachments'"
            >
              <div class="service-detail-height">
                <FileTemplate
                  :attachments="detail.product_attachments"
                ></FileTemplate>
              </div>
            </v-tab-item>
            <v-tab-item
              v-if="getPermission('warranty:view')"
              :value="'warranty'"
            >
              <div class="service-detail-search-height">
                <Warranty :detail="detail"></Warranty>
              </div>
            </v-tab-item>
            <v-tab-item v-if="getPermission('job:view')" :value="'job'">
              <div class="service-detail-search-height">
                <Tickets :detail="detail" type="product"></Tickets>
              </div>
            </v-tab-item>
            <v-tab-item
              v-if="getPermission('quotation:view')"
              :value="'quotation'"
            >
              <div class="service-detail-search-height">
                <Quotations :detail="detail" type="product"></Quotations>
              </div>
            </v-tab-item>
            <v-tab-item v-if="getPermission('invoice:view')" :value="'invoice'">
              <div class="service-detail-search-height">
                <Invoices :detail="detail" type="product"></Invoices>
              </div>
            </v-tab-item>
            <v-tab-item
              v-if="getPermission('product-adjustment:view')"
              :value="'product-adjustment'"
            >
              <div class="service-detail-search-height">
                <ProductAdjustment :detail="detail"></ProductAdjustment>
              </div>
            </v-tab-item>
            <v-tab-item v-if="getPermission('history:view')" :value="'history'">
              <div class="service-detail-search-height">
                <InternalHistoryDetail
                  type="product"
                  :type_id="detail.id"
                ></InternalHistoryDetail>
              </div>
            </v-tab-item>
            <v-tab-item
              v-if="getPermission('product:view')"
              :value="'inventory'"
            >
              <InventoryTab
                type="goods"
                :type_id="detail.id"
                :inbond-date="detail.purchase_date"
                :inbond-doc="detail.purchase_order"
              ></InventoryTab>
              <div v-if="false">
                <v-layout
                  class="p-2 pr-0 border-bottom-light-grey min-height-57px"
                >
                  <v-flex class="d-flex font-level-3-bold my-auto">
                    <!-- <v-btn-toggle
                    dense
                      v-model="inventorySummary"
                      color="blue accent-3"
                      mandatory
                    >
                      <v-btn value="available"> Available (0) </v-btn>

                      <v-btn value="sold"> Sold (0)</v-btn>
                    </v-btn-toggle> -->
                    <span class="form-title font-size-20 font-weight-700">
                      Available
                    </span>
                  </v-flex>
                  <v-spacer></v-spacer>
                </v-layout>
                <div v-if="!pageLoading">
                  <template v-if="inventoryData?.length">
                    <v-simple-table
                      width="100%"
                      class="detail-table table-head-sticky invetory_table"
                      fixed-header
                      height="600"
                    >
                      <thead>
                        <tr style="background-color: #f3f6f9; color: #24326d">
                          <th
                            width="50"
                            class="py-0 px-1 font-size-13 text-uppercase font-weight-500"
                          >
                            #
                          </th>
                          <th
                            class="py-0 px-1 font-size-13 text-uppercase font-weight-500"
                          >
                            serial number
                          </th>
                          <th
                            class="py-0 px-1 font-size-13 text-uppercase font-weight-500"
                          >
                            Inbond date
                          </th>
                          <th
                            class="py-0 px-1 font-size-13 text-uppercase font-weight-500"
                          >
                            Transaction number
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(row, index) in inventoryData"
                          :key="index"
                          :class="{
                            'custom-border-top': index == 0,
                            'orange_lighten-5': index % 2 == 0,
                          }"
                        >
                          <td class="p-2 border-top-light-grey">
                            {{ index + 1 }}
                          </td>

                          <td class="p-2 border-top-light-grey text-capitalize">
                            <template v-if="row.text">
                              <span class="mr-1">{{ row.text }}</span>
                              <showBarcode
                                :barcodeUrl="row.barcode_image"
                                :barcode-text="row.text"
                                title="Barcode"
                              />
                            </template>

                            <template v-else>
                              <em class="text-muted">no Serial no.</em>
                            </template>
                          </td>
                          <td class="p-2 border-top-light-grey">
                            <template v-if="detail.purchase_date">
                              {{ formatDate(detail.purchase_date) }}
                            </template>
                            <template v-else>
                              <em class="text-muted">no inbond date</em>
                            </template>
                          </td>
                          <td class="p-2 border-top-light-grey">
                            <template v-if="row.transaction_no">
                              {{ formatDate(row.transaction_no) }}
                            </template>
                            <template v-else>
                              <em class="text-muted">no transaction no</em>
                            </template>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </template>
                  <p v-else class="m-0 row-not-found text-center">
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no price at the moment.
                  </p>
                </div>
                <div v-else class="mx-20 my-40 text-center">
                  <v-progress-linear
                    color="orange darken-2"
                    indeterminate
                    height="6"
                  ></v-progress-linear>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item value="attachment">
              <AdditionalDocuments
                :type-id="detail.id"
                class="mx-0 mb-3"
                type-text="Product Documents"
                :type="9"
                :extensive="false"
              ></AdditionalDocuments>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-container>
    </template>
  </DetailTemplate>
</template>

<script>
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH } from "@/core/services/store/request.module";
import Overview from "@/view/pages/product/detail/Overview";
import ProductPriceListing from "@/view/pages/product/detail/ProductPriceListing";
//import InventoryListing from "@/view/pages/product/detail/InventoryListing";
import InventoryTab from "@/view/pages/part/detail/InventoryTab";
import ProductSpplierListing from "@/view/pages/product/detail/ProductSpplierListing";
import Warranty from "@/view/pages/product/detail/Warranty";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import Quotations from "@/view/pages/partials/Detail/Quotation";
import Invoices from "@/view/pages/partials/Detail/Invoice";
import ProductAdjustment from "@/view/pages/product/detail/ProductAdjustment";
import ImageNew from "@/view/pages/partials/Detail/ImageNew";
import Images from "@/view/pages/partials/Detail/Image";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
import showBarcode from "@/view/components/showBarcode.vue";
import { SET_ACTIVE_FIELD_DATA } from "@/core/services/store/custom.fields.module.js";
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      totalSerialnumRows: 0,
      dataLoading: null,
      inventorySummary: "available",
      inventoryData: [],
      detail: new Object(),
      /*   productTab: "product", */
      productMoreAction: [
        /*{
          title: "Convert to Equipment",
          action: "convert_to_equipment",
          icon: "mdi-power-plug-outline",
          disabled: false,
        },*/
        /*  {
          title: "Duplicate",
          action: "duplicate",
          icon: "mdi-content-duplicate",
          disabled: false,
        }, */
        // {
        //   title: "Generate Adjustment",
        //   action: "generate_adjustment",
        //   icon: "mdi-content-duplicate",
        //   disabled: false,
        // },
        {
          title: "Mark as Active",
          action: "mark_as_active",
          icon: "mdi-check-all",
          disabled: false,
        },
        {
          title: "Mark as In-Active",
          action: "mark_as_inactive",
          icon: "mdi-check-all",
          disabled: false,
        },
      ],
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "product",
          permission: "product",
          disabled: false,
        },
        /*{
          title: "Adjustment",
          icon: "mdi-account-multiple",
          key: "product-adjustment",
          permission: "product-adjustment",
          disabled: false,
        },*/

        {
          title: "Supplier",
          icon: "mdi-phone",
          key: "supplier",
          permission: "product",
          disabled: false,
        },
        {
          title: "Pricing",
          icon: "mdi-phone",
          key: "pricing",
          permission: "product",
          disabled: false,
        },
        /*  {
          title: "Images",
          icon: "mdi-phone",
          key: "image",
          permission: "product",
          disabled: false,
        }, */

        {
          title: "Attachments",
          icon: "mdi-phone",
          key: "attachment",
          permission: "product",
          disabled: false,
        },
        /* {
          title: "Warranty",
          icon: "mdi-credit-card",
          key: "warranty",
          permission: "warranty",
          disabled: false,
        }, */

        /*{
          title: "Jobs",
          icon: "mdi-credit-card",
          key: "job",
          permission: "job",
          disabled: false,
        },
        {
          title: "Quotations",
          icon: "mdi-account-multiple",
          key: "quotation",
          permission: "quotation",
          disabled: false,
        },
        {
          title: "Invoices",
          icon: "mdi-account-multiple",
          key: "invoice",
          permission: "invoice",
          disabled: false,
        },*/
        {
          title: "Inventory",
          icon: "mdi-account-multiple",
          key: "inventory",
          permission: "product",
          disabled: false,
        },
        {
          title: "History",
          icon: "mdi-account-multiple",
          key: "history",
          permission: "history",
          disabled: false,
        },
      ],
      formValid: true,
      formLoading: false,
      pageLoading: true,
      //productTab: null,
      product: 0,
    };
  },
  components: {
    DetailTemplate,
    Overview,
    ProductPriceListing,
    InventoryTab,
    //InventoryListing,
    ProductSpplierListing,
    Warranty,
    Tickets,
    ImageNew,
    Images,
    FileTemplate,
    Quotations,
    Invoices,
    ProductAdjustment,
    InternalHistoryDetail,
    AdditionalDocuments,
    showBarcode,
  },
  methods: {
    patchProductParams(status) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "product",
          data: {
            status,
            products: [_this.product],
          },
        })
        .then(() => {
          _this.getProductDetail();
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getProductInventory() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: `all-serial-number/goods/${this.product}`,
        })
        .then((data) => {
          this.inventoryData = data.data.rows;
          this.totalSerialnumRows = data.data.total_rows;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "convert_to_equipment":
          _this.convertToEquipment();
          break;
        case "duplicate":
          _this.duplicateProduct();
          break;
        case "generate_adjustment":
          _this.adjustmentProduct();
          break;
        case "mark_as_active":
          _this.patchProductParams(1);
          break;
        case "mark_as_inactive":
          _this.patchProductParams(0);
          break;
      }
    },
    convertToEquipment() {
      this.$router.push(
        this.getDefaultRoute("equipment.create", {
          query: {
            product: this.product,
          },
        })
      );
    },
    duplicateProduct() {
      this.$router.push(
        this.getDefaultRoute("product.create", {
          query: {
            duplicate: this.product,
          },
        })
      );
    },
    adjustmentProduct() {
      // alert(23);
      console.log(this.product, "hello");
      this.$router.push(
        this.getDefaultRoute("adjustment.create", {
          query: {
            generate_adjustment: this.product,
            product_type: "goods",
          },
        })
      );
    },
    getProductDetail() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "product/" + _this.product,
        })
        .then(({ data }) => {
          _this.detail = data;
          //console.log(_this.detail,"_this.detail");
          if (Array.isArray(this.detail.custom_fields_product)) {
            this.$store.commit(
              SET_ACTIVE_FIELD_DATA,
              this.detail.custom_fields_product
            );
          }
          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Product", route: "product" },
            { title: "Detail" },
            { barcode: data.barcode },
          ]);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.getProductDetail();
    _this.getProductInventory();
    this.productTab = "product";

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Product", route: "product" },
      { title: "Detail" },
    ]);
  },
  created() {
    const _this = this;
    _this.product = _this.$route.params.id;
    if (!_this.product || _this.product <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.productTab = _this.$route.query.tab;
    }
  },
  computed: {
    productTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (this.productTab != val) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "product";
      },
    },
  },
};
</script>
<style>
#attachment .overflow-y {
  max-height: none !important;
}
</style>
